import React from 'react'
import Header from './header'
import Footer from './footer'
import "../styles/main.scss"
import {Helmet} from 'react-helmet'

const Layout =(props)=>{
    const rootPath = `${__PATH_PREFIX__}/`
    const isRootPath = props.location.pathname === rootPath
    const isBlogPage = props.location.pathname.startsWith('/blog')
    const isTagPage = props.location.pathname.startsWith('/tags')
    return(
        <div>
            <Helmet>
                <script src="https://code.jquery.com/jquery-3.4.1.slim.min.js" integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n" crossorigin="anonymous"></script>
                <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js" integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo" crossorigin="anonymous"></script>
                <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js" integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6" crossorigin="anonymous"></script>
            </Helmet>
            <Header/>
            {props.children}
            <Footer isRootPath={isRootPath} isBlogPage={isBlogPage}/>
        </div>
    )
}
export default Layout
import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/mylayout"
import SEO from "../components/seo"
import {toKebab} from '../utils'
// import " styles/vendors/prismjs/themes/prism-vs.css"
import "../styles/vendors/prismjs/themes/prism-ateliersulphurpool.css"
// import "../styles/vendors/prismjs/themes/prism-duotone.css"
// import "../styles/vendors/prismjs/themes/prism-material.css"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div className="container Blog-Post">
        <div className="control-container">
          <article
            itemScope
            itemType="http://schema.org/Article"
            >
            <header>
              <h1 itemProp="headline" className="Blog-Post__title">{post.frontmatter.title}</h1>
              <p className="Blog-Post__date">{post.frontmatter.date}</p>
              <p className="Blog-Post__tags">
              {post.frontmatter.tags == null ? null : post.frontmatter.tags.map(tag => {
                  return(
                      <Link to={`/tags/${toKebab(tag)}`} className="Blog-Post__tag">{tag.charAt(0).toUpperCase() + tag.slice(1)}</Link>
                  )
              })}
              </p>
            </header>
            <section
              dangerouslySetInnerHTML={{ __html: post.html }}
              itemProp="articleBody"
              />
            <hr />
            <footer>
              <Bio />
            </footer>
          </article>
          <nav className="blog-post-nav">
            <ul>
              <li>
                {previous && (
                  <Link to={`/blog${previous.fields.slug}`} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={`/blog${next.fields.slug}`} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
